import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeading from '../components/pageheading'
import ContentImage from '../components/contentimage'


const HowWeWork = ({data}) => (

    <Layout pageClass="info-page how-we-work-page">
    <SEO title="How We Work" description="Modus builds quality and certainty into every building project." />
    <PageHeading title="How We Work" imageData={data.headerImage} />
    <main className="main ">
        <div>
        <div className="inner typography">
            
            <div className="text-holder">
                <p>
                    We aim to build quality and certainty into every project. 
                </p>

                <p >
                    While we are happy to engage in the traditional tender process, we believe clients receive more value from a cost reimbursement approach (also known as ‘open book.’) This involves partnering with the architect and client in the early stages of the project, before detailed plans are developed.
                </p>

  
                    <h3>
                        Here’s why we think this approach delivers better results for all involved:
                    </h3>

                    <h4>Get insight and expertise up front.</h4>
                    <p>
                        Bryan and the Modus Developments team can help you review concept drawings of the design to provide pointers on details, techniques and materials. This enables more accurate costings and reduces hold-ups at the consent and construction stages. When detailed drawings are signed off, you will know that the budget is realistic.
                    </p>

                    <h4>No corners cut.</h4>
                    <p>
                        With the traditional tender process, contractors are incentivised to reduce the quantum. They may be over-optimistic about the ease of the job, or they may simply lack the experience to accurately cost it. You don’t want to be having a conversation half way through your project that starts with: “There’s something we forgot…”
                    </p>

                    <h4>Transparency on costs.</h4>
                    <p>
                        We provide a detailed budget for all labour, materials and administration costs. All supplier invoices are made available for your review. With the addition of a modest contingency, you will know what your project is likely to cost. Monthly reviews provide ongoing assurance that everything is on track. 
                    </p>

                    <h4>Save on Quantity Surveyor fees.</h4>
                    <p>
                        The detailed work Bryan puts in up front – with systems based on data from hundreds of previous projects – means his estimates of quantities and processes are realistic.
                    </p> 

                    <h4>Every project is unique.</h4>
                    <p>
                        Your new build or renovation is a significant investment. Attention to detail in the planning stages can avoid costly variations later on. By engaging our expertise up front, you can create something distinctive without straining your budget.
                    </p>

                </div>
                <ContentImage image={data.contentImage} alt="Kitchen" />
            </div>
           
           

        


        </div>
    </main>
  </Layout>

)
export const pageQuery = graphql`
     {
        headerImage: imageSharp(resolutions: {originalName: {eq: "AE1727.jpg"}}) { 
            ...ResponsiveImage
        }
        contentImage: imageSharp(resolutions: {originalName: {eq: "RN.StHeliers.102.jpg"}}) { 
            big: fluid (maxWidth: 630, maxHeight: 1370, quality: 100, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
            }
            medium: fluid (maxWidth: 435, maxHeight: 1755, quality: 100, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
            }
            small: fluid (maxWidth: 720, maxHeight: 540, quality: 100, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
            }
        }
    
    } 
`

export default HowWeWork